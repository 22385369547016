import { render, staticRenderFns } from "./ImageView.vue?vue&type=template&id=39a4cba9&scoped=true"
import script from "./ImageView.vue?vue&type=script&lang=js"
export * from "./ImageView.vue?vue&type=script&lang=js"
import style0 from "./ImageView.vue?vue&type=style&index=0&id=39a4cba9&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39a4cba9",
  null
  
)

export default component.exports